import ROUTES from './routes.const';

export const SideBarList = [
  {
    label: 'Dashboard',
    key: 'dashboard',
    link: ROUTES.DASHBOARD,
    icon: '../assets/SidebarIcon/dashboard.svg',
  },
  {
    label: 'Submissions',
    key: 'submissions',
    link: ROUTES.SUBMISSIONS,
    icon: '../assets/SidebarIcon/activities.svg',
  },
  {
    label: 'Audit',
    key: 'audit',
    link: ROUTES.AUDIT,
    icon: './assets/SidebarIcon/audit.svg',
  },
  {
    label: 'Submissions to Regulator',
    key: 'submissions-to-regulator',
    link: ROUTES.SUBMISSIONS_REGULATOR,
    icon: './assets/SidebarIcon/submissionsRegulator.svg',
  },
  {
    label: 'Installers',
    key: 'installers',
    link: ROUTES.INSTALLERS,
    icon: '../assets/SidebarIcon/Installers.svg',
  },
  {
    label: 'Projects',
    key: 'project-management',
    link: ROUTES.PROJECT_MANAGEMENT,
    icon: '../assets/SidebarIcon/Project.svg',
  },
  {
    label: 'Certificate Value',
    key: 'certificate-value',
    link: ROUTES.CERTIFICATE_VALUE,
    icon: './assets/SidebarIcon/CertificateValue.svg',
  },
];

export const CERTIFICATE_VALUE = [
  { label: 'ACCU', value: 'ACCU' },
  { label: 'ESC', value: 'ESC' },
  { label: 'LGC', value: 'LGC' },
  { label: 'PRC', value: 'PRC' },
  { label: 'STC', value: 'STC' },
  { label: 'VEEC', value: 'VEEC' },
];

export const ACTIVITIES_COLS = [
  { header: 'Activity' },
  { header: 'Installer' },
  { header: 'Date' },
  { header: 'Status' },
  { header: '' },
  { header: '' },
];

export const AUDIT_JOB_COLS = [
  { header: 'Name' },
  { header: 'Value' },
  { header: 'Meta Data' },
  { header: 'Reject' },
  { header: 'Pass' },
  { header: '' },
  { header: '' },
];

export const BUNDLE_COLS = [
  // { header: '' },
  // { header: 'Bundle Name' },
  { header: 'Installer Name' },
  { header: 'Creation Date/Time' },
  { header: 'Status' },
];

export const ACTIVITIES_BUNDLE_COLS = [
  { header: 'Scheme Name' },
  { header: 'Activity Name' },
  { header: 'Type of Certificate' },
  { header: 'No. of Certificates' },
  { header: 'Status' },
  // { header: '' },
];

export const INSTALLER_COLS = [
  { header: 'Installer' },
  { header: 'Email' },
  { header: 'Status' },
  { header: '' },
];

export const DASHBOARD_TABLE = [
  { header: 'Name' },
  { header: 'New' },
  { header: 'Assessments' },
  { header: 'Installation' },
  { header: 'Audit' },
  { header: 'Complete' },
];

export const DASHBOARD_JOB_COLS = [
  { header: 'Job' },
  { header: 'Location' },
  { header: 'Status' },
];

export const USER_COLS = [
  { field: 'name', header: 'User' },
  { field: 'email', header: 'E-mail' },
  { field: 'status', header: 'Status' },
  { field: '', header: '' },
];

export const ACTIVITIES_USER_COLS = [
  { header: 'Scheme - Activity' },
  { header: '' },
  { header: '' },
];

export const JOB_COLS = [
  { header: 'Job' },
  { header: 'Location' },
  { header: 'Dwelling Type' },
  { header: 'Status' },
  { header: '' },
];

export const DWELLING_TYPE = [
  { label: 'Residential', value: 1 },
  { label: 'Commercial', value: 2 },
];

export const PROJECT_COLS = [
  { header: 'Project Name' },
  { header: 'Customer' },
  { header: 'Address' },
  { header: 'Status' },
  { header: '' },
];

export const PROJECT_TYPE = [
  { label: 'Commercial', value: 0 },
  { label: 'Residential', value: 1 },
];

export const SUBMISSION_REGULATOR_COLS = [
  { header: 'Scheme Name' },
  { header: 'Activity Name' },
  { header: 'Type of Certificate' },
  { header: 'No of Certificates' },
  { header: 'Created Date' },
  { header: 'Submission Date' },
  { header: 'Status' },
  { header: '' },
];

export const SUBMITTED_BUNDLE_COLS = [
  { header: 'Certificate' },
  { header: 'Type' },
  { header: 'Submission Date' },
  { header: 'Status' },
  { header: '' },
];
